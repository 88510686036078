import { Inject, Injectable, Optional, Provider } from '@angular/core';
import { HomeCoverImage } from '@portal/wen-backend-api';
import {
  ClientCustomizationProvider,
  WEATHER_CONFIG, WeatherConfig,
  WenClientStyleConfig,
  WenClientWeatherConfig,
} from '@portal/wen-widget';

@Injectable()
export class BiolandClientCustomization extends ClientCustomizationProvider {

  private readonly COLOR_PRIMARY = '#e6a444';
  private readonly CHANNEL_COLOR = '#f2f2f2';

  constructor(@Optional() @Inject(WEATHER_CONFIG) private weatherConfig: WeatherConfig) {
    super();
  }

  getDefaultStyleConfig() {
    const config: WenClientStyleConfig = {
      primaryColor: this.COLOR_PRIMARY,
      logo: '/assets/static/image/bioland-logo.svg',
      homeCoverPortraitImage: '/assets/wen-widget/image/home/home-cover-portrait.png',
      homeCoverLandscapeImage: '/assets/wen-widget/image/home/home-cover-landscape.jpg',
      welcomeLogo: '/assets/static/image/home-page-background.svg',
      channelViewBackgroundColor: this.CHANNEL_COLOR,
      headerTextColor: 'white',
      headerSubTitleColor: 'white',
      headerBackgroundColor: '#3ca1ad',
      headerCommandColorBack: 'white',
      headerCommandColorCreate: this.COLOR_PRIMARY,
      headerCommandColorWizardNext: 'white',
      headerCommandColorOpenProfile: 'white',
      headerCommandColorClose: 'white',
      searchBarCancelButtonColor: 'white',
      searchBarTextColor: '#7E7E7E',
      searchBarBackgroundColor: 'white',
      errorColor: '#f44336',
      linkColor: '#1467ba',
    };
    return config;
  }

  getDefaultWeatherConfig(): WenClientWeatherConfig {
    return {
      location: this.weatherConfig?.location,
      countryCode2Letters: this.weatherConfig?.countryCode2Letters,
      goToLink: this.weatherConfig?.goToLink
    };
  }

  getDefaultHomeCoverImageConfig(): HomeCoverImage {
    return {
      landscape: '/assets/wen-widget/image/home/home-cover-landscape.jpg',
      portrait: '/assets/wen-widget/image/home/home-cover-portrait.png'
    };
  }

}

export const BIOLAND_CLIENT_CUSTOMIZATION_PROVIDER: Provider = {
  provide: ClientCustomizationProvider,
  useClass: BiolandClientCustomization
};
