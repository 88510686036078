import { Injectable, Provider } from '@angular/core';
import { RadiusConfiguration } from '@portal/wen-widget';

@Injectable()
export class BiolandRadiusConfiguration extends RadiusConfiguration {
  getCustomGeoRadius(): number[] {
    return [
      1, 2, 3, 4, 5, 6, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300
    ];
  }
  getDefaultRadius(): number {
    return 10;
  }
}

export const BIOLAND_RADIUS_CONFIGURATION: Provider = {
  provide: RadiusConfiguration,
  useClass: BiolandRadiusConfiguration,
};
